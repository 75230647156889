<template>
  <tr>
    <td>{{ props.item.code }}</td>
    <td>
      <promise-key
        :promise="props.item.case"
        promise-key="recipient.name" />
    </td>
    <td>{{ $n(props.item.installmentSelected.originalValue, 'currency') }}</td>
    <td>
      <v-tooltip
        top
        content-class="table_trasparent">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on">
            <v-icon color="grey lighten-1">
              info
            </v-icon>
          </v-btn>
        </template>
        <v-simple-table dense>
          <tbody>
            <tr
              v-for="(item, index) in props.item.installmentSelected.details"
              :key="index">
              <td>{{ $t('calculateParams.' + item.type) }}</td>
              <td>{{ $n(item.value, 'currency') }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tooltip>
      {{ $n(additions, 'currency') }}
    </td>
    <td>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on">
            <v-icon color="grey lighten-1">
              info
            </v-icon>
          </v-btn>
        </template>
        <span>{{ props.item.installmentSelected.parcel }} x {{ $n(props.item.installmentSelected.parcelValue, 'currency') }}</span>
      </v-tooltip>
      {{ $n(props.item.installmentSelected.currentValue, 'currency') }}
    </td>
    <td>{{ props.item.updatedAt }}</td>
    <td class="text-center">
      <term-download v-if="props.item.term" :file="props.item.termFile" />
    </td>
  </tr>
</template>

<script>
  import PromiseKey from '@/components/ui/PromiseKey'
  import TermDownload from '@/components/ui/TermDownload'

  export default {
    components: { PromiseKey, TermDownload },
    props: {
      props: Object
    },
    computed: {
      additions() {
        return this.props.item.installmentSelected.details.reduce((total, detail) => detail.value + total, 0)
      }
    }
  }
</script>

<style lang="sass">
  .table_trasparent
    .theme--light.v-data-table
      background: transparent
      color: #fff
</style>
