<template>
  <tr tabindex="0">
    <td>{{ props.item.numDoc }}</td>
    <td>{{ props.item.payer.nome }}</td>
    <td class="text-center">
      {{ props.item.parcel }}
    </td>
    <td>{{ props.item.dueAt }}</td>
    <td>{{ $n(props.item.amount, 'currency') }}</td>
    <td>{{ $t('bankSlip.state.' + props.item.state) }}</td>
  </tr>
</template>

<script>
  export default {
    props: {
      props: Object
    }
  }
</script>
