<template>
  <tr>
    <td>{{ props.item.code }}</td>
    <td>
      <promise-key
        :promise="props.item.case"
        promise-key="recipient.name" />
    </td>
    <td>{{ props.item.updatedAt }}</td>
    <td>{{ props.item.installmentSelected.parcel }} x {{ $n(props.item.installmentSelected.parcelValue, 'currency') }}</td>
    <!-- <td class="text-center">{{ paidParcels.parcels }}</td> -->
    <!-- <td>{{ $n(paidParcels.total, 'currency') }}</td> -->
    <!-- <td class="text-center">{{ toReceiveParcels.parcels }}</td> -->
    <!-- <td>{{ $n(toReceiveParcels.total, 'currency') }}</td> -->
    <td>{{ $n(props.item.installmentSelected.currentValue, 'currency') }}</td>
  </tr>
</template>

<script>
  // TODO pegar boletos da proposta
  import PromiseKey from '@/components/ui/PromiseKey'

  export default {
    components: { PromiseKey },
    props: {
      props: Object
    },
    computed: {
      paidParcels () {
        const payments = this.props.item.ticketsPayments
        const paid = payments.filter(payment => payment.state === 'payed')
        const total = paid.reduce((total, parcel) => parcel.amount + total, 0)
        return { total: total, parcels: paid.length }
      },
      toReceiveParcels () {
        const total = this.props.item.currentValue - this.paidParcels.total
        const parcels = this.props.item.installmentSelected.parcel - this.paidParcels.parcels
        return { total: total, parcels: parcels }
      }
    }
  }
</script>
