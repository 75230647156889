<template>
  <div class="list-cases">
    <app-list
      :total-entries="proposals.length"
      :entries="proposals"
      :headers="headers"
      :pagination="pagination"
      :is-render="isRender"
      :loading-list="loading"
      @paginate="paginate">
      <template v-slot:row="row">
        <row :props="row.props" />
      </template>
    </app-list>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row },
    data () {
      return {
        headers: [
          { text: 'Código', value: 'code', sortable: false },
          { text: 'Nome', value: 'recipient_name', sortable: false },
          { text: 'Valor Original', value: 'installmentSelected.originalValue', sortable: false },
          { text: 'Valor Corrigido', value: 'installmentSelected.currentValue', sortable: false },
          { text: 'Recusada em', value: 'updated_at', sortable: true },
          { text: 'Motivo', value: 'refused', sortable: false }
        ],
        pagination: {
          sortBy: [sessionStorage.getItem('ProposalPaginationSortBy') || 'updated_at'],
          sortDesc: [JSON.parse(sessionStorage.getItem('ProposalPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('ProposalPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('ProposalPaginationRowsPerPage') || 13),
          rowsPerPageItems: [13, 25, 50, 100]
        }
      }
    },
    computed: {
      ...mapGetters({
        proposals: 'proposal/list',
        loading: 'proposal/listLoading',
        isRender: 'proposal/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          // sessionStorage.setItem('ProposalPaginationSortBy', pagination.sortBy)
          // sessionStorage.setItem('ProposalPaginationDescending', pagination.descending)
          // sessionStorage.setItem('ProposalPaginationRowsPerPage', pagination.rowsPerPage)
        },
        deep: true
      }
    },
    beforeMount () {
      this.$store.dispatch('proposal/getList', 'refused')
    },
    methods: {
      paginate (paginate) {
        this.pagination = paginate
      }
    }
  }
</script>
