<template>
  <div class="container-page">
    <app-list
      :total-entries="length"
      :entries="invites"
      :headers="headers"
      :state-list="stateList"
      :pagination="pagination"
      :is-render="isRender"
      :loading-list="loading"
      :has-search="false"
      :has-filter="true"
      :has-chart="false"
      :series="series"
      chart-name="Canais de Comunicação"
      @paginate="paginate">
      <template v-slot:row="row">
        <row
          :props="row.props"
          @modalResend="modalResend" />
      </template>
    </app-list>

    <modal
      ref="communicationModal"
      :item="rowSelected" />
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import Modal from '@/components/ui/ModalResend'
  import { mapGetters } from 'vuex'

  const rowHeight = 48
  const compensationArea = 350
  const pageSize = () => parseInt((window.innerHeight - compensationArea) / rowHeight)

  export default {
    components: {
      AppList,
      Row,
      Modal
    },
    data () {
      return {
        messageModal: '',
        showModal: false,
        stateList: [
          { label: 'Todos', value: '' },
          { label: 'Criado', value: 'created' },
          { label: 'Agendada', value: 'scheduled' },
          { label: 'Processado', value: 'processed' },
          { label: 'Entregue', value: 'delivered' },
          { label: 'Aberto', value: 'open' },
          { label: 'Acessado', value: 'click' },
          { label: 'Ignorado', value: 'bounce' },
          { label: 'Recusado', value: 'dropped' },
          { label: 'Spam', value: 'spamreport' }

        ],
        headers: [
          { text: 'Tipo', value: 'type', sortable: false },
          { text: 'Origem', value: 'code', sortable: false },
          { text: 'Token', value: 'token', align: 'center', sortable: false },
          { text: 'Status', value: 'status', align: 'center', sortable: false },
          { text: 'Criado em', value: 'insertedAt', align: 'center', sortable: false },
          { text: 'Atualizado em', value: 'updatedAt', align: 'center', sortable: false },
          { text: 'Reenviar', value: 'resend', sortable: false, align: 'center' }
        ],
        pagination: {
          state: sessionStorage.getItem('InvitePaginationState') || '',
          page: parseInt(sessionStorage.getItem('InvitePaginationPage') || 1),
          rowsPerPage: pageSize(),
          rowsPerPageItems: [pageSize()]
        },
        series: [],
        rowSelected: {}
      }
    },
    computed: {
      ...mapGetters({
        length: 'invites/length',
        invites: 'invites/list',
        loading: 'invites/listLoading',
        isRender: 'invites/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          sessionStorage.setItem('InvitePaginationState', pagination.state)
          sessionStorage.setItem('InvitePaginationRowsPerPage', pagination.rowsPerPage)
          this.$store.dispatch('invites/getList', pagination)
        },
        deep: true
      },
      deep: true
    },
    beforeMount () {
      this.$store.dispatch('invites/getList', this.pagination)
    },
    methods: {
      paginate (paginate) {
        this.pagination = paginate
      },
      modalResend (item) {
        this.rowSelected = item
        this.$refs.communicationModal.modalResend(item)
      }
    }
  }
</script>
