<template>
  <tr
    tabindex="0"
    class="not-read"
    :class="{'table-list-opened': props.expanded }"
    @click="props.expanded = !props.expanded"
    @keydown.enter="props.expanded = !props.expanded">
    <td>
      <label-icon :type="$t('invite.channel.' + props.item.channel)" />
    </td>
    <td>
      <promise-link
        v-if="props.item.case"
        :promise="props.item.case"
        router-name="CaseChannelsList"
        router-path="caseId"
        promise-key="code" />
      <promise-link
        v-else
        :promise="props.item.company"
        router-name="CompanyGeneral"
        router-path="companyId"
        promise-key="fantasyName" />
    </td>
    <td class="text-center">
      {{ props.item.token }}
    </td>
    <td class="text-center">
      <p class="mb-0">
        {{ $t('invite.state.' + props.item.state) }}
      </p>
    </td>
    <td class="text-center">
      {{ $d(props.item.insertedAt.toDate(), 'long') }}
    </td>
    <td class="text-center">
      {{ $d(props.item.updatedAt.toDate(), 'long') }}
    </td>
    <td class="text-center">
      <v-btn
        title="Reenviar"
        icon
        small
        color="bgColor"
        class="mini"
        fab
        depressed
        :disabled="checkState(props.item.state)"
        @click="$emit('modalResend', props.item)">
        <v-icon
          small
          color="primary">
          {{ $root.icons.action.replay }}
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
  import LabelIcon from '@/components/ui/LabelIcon'
  import PromiseLink from '@/components/ui/PromiseLink'
  import { CHANNELS_STATE } from '@/utils/states'

  export default {
    components: { LabelIcon, PromiseLink },
    props: {
      props: Object
    },
    methods: {
      checkState (state) {
        let result = state === CHANNELS_STATE.email.dropped || state === CHANNELS_STATE.email.bounce
        return result || this.hideActionButton
      }
    }
  }
</script>
