<template>
  <modal
    :show="showModal"
    max-width="480"
    btn-primary-label="Reenviar"
    @close="closeModal"
    @submit="saveModal">
    <span slot="title">Reenviar convite</span>

    <div slot="content">
      <h2
        class="subtitle-1"
        v-html="messageModal" />
    </div>
  </modal>
</template>

<script>
  import Modal from '@/components/ui/Modal'

  export default {
    components: { Modal },
    props: {
      item: {
        type: Object
      }
    },
    data: () => ({
      messageModal: '',
      showModal: false
    }),
    methods: {
      async modalResend (data) {
        this.$root.$emit('snackbar', {show: false})
        this.showModal = true
        let channel = this.$t('channel.' + data.channel)

        this.messageModal = `Deseja reenviar <b>${channel}</b> de convite relacionado ao token <b>${data.token}</b>?`
      },
      closeModal () {
        this.showModal = false
      },
      saveModal () {
        this.$store.commit('app/setLoading', true)

        this
          .$store
          .dispatch('invites/resend', this.item.id)
          .then(this._success)
          .catch(this._failure)
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Reenvio realizado com sucesso'})
        this.$store.commit('app/setLoading', false)
        this.closeModal()
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema, tente novamente'})
      }
    }
  }
</script>
