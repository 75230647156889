<template>
  <v-container class="container-page">
    <loading v-if="questionsLoading > 0" />

    <v-card v-else class="mt-0 transparent">
      <v-card-text class="px-0 py-0">
        <v-container
          fluid
          grid-list-lg
          class="mt-0">
          <v-layout row wrap justify-center>
            <v-flex
              v-for="(questions, group) in questionGroups"
              :key="group"
              xs12>
              <h2 class="mb-4">
                {{ $t('case.state.' + group) }}
              </h2>

              <v-layout row wrap>
                <v-flex v-for="question in questions" :key="question.id" xs12 sm6>
                  <h3>
                    {{ $t('surveys.' + question.id, { name: subject(group), company_name: companyName }) }}
                  </h3>

                  <apexcharts
                    width="100%"
                    type="bar"
                    height="150"
                    :options="survey.chartOptions"
                    :series="series(question.stats)"
                    class="survey-chart" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ widgetsMixin ],
    data () {
      return {
        survey: {
          legend: { show: false },
          chartOptions: {
            chart: {
              offsetY: -30,
              type: 'bar',
              stacked: true,
              stackType: '100%',
              toolbar: { show: false }
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: { enabled: true, hideOverflowingLabels: false },
              },
            },
            labels: [],
            xaxis: { labels:  { show: false  } },
            yaxis: { labels:  { show: false  } },
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'left',
              textAnchor: 'start',
              offsetY: -10,
              containerMargin: { right: 15, top: 10 },
              labels: { color: this.$vuetify.theme.themes.light.darkGray },
              markers: {
                size: 5,
                strokeWidth: 0,
                offsetX: 0,
                offsetY: 0,
                radius: 15,
                shape: 'circle'
              },
              formatter: (stat, chart) => this.$t('surveys.stats.' + stat)
            },
            colors: [
              this.$vuetify.theme.themes.light.primary,
              this.$vuetify.theme.themes.light.secondary,
              this.$vuetify.theme.themes.light.accent,
              this.$vuetify.theme.themes.light.action,
              this.$vuetify.theme.themes.light.info
            ],
            tooltip: {
              x: { show: false },
              y: {
                title: { formatter: (stat) => this.$t('surveys.stats.' + stat) }
              }
            }
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        questions: 'surveys/questions',
        questionsLoading: 'surveys/listLoading',
        companyName: 'currentCompany/name'
      }),
      questionGroups () {
        return this.questions.reduce((result, question) => {
          question.type.map(kind => {
            return result[kind] = [...result[kind] || [], question]
          })

          return result
        }, {})
      }
    },
    beforeMount () {
      this.$store.dispatch('surveys/getQuestions')
    },
    methods: {
      series (stats) {
        return Object.keys(stats).map(key => {
          return { name: key, data: [stats[key]] }
        })
      },
      subject (type) {
        return type.includes('negotiation') ? 'Negociador' : ''
      }
    }
  }
</script>

<style lang="sass">
  .survey-chart
    .apexcharts-legend-series
      display: flex !important

    .apexcharts-legend-marker
      margin-right: 5px
</style>
