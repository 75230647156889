<template>
  <div class="container-page">
    <template>
      <app-list
        :total-entries="bankSlips.length"
        :entries="bankSlips"
        :headers="headers"
        :pagination="pagination"
        :is-render="isRender"
        :loading-list="loading"
        has-search
        @paginate="paginate">
        <template v-slot:row="row">
          <row :props="row.props" />
        </template>
      </app-list>
    </template>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row },
    data () {
      return {
        headers: [
          { text: 'Num Doc', value: 'name' },
          { text: 'Nome da campanha', value: 'name', sortable: false },
          { text: 'Parcela', value: 'proposal', sortable: false, align: 'center' },
          { text: 'Vencimento', value: 'proposal', sortable: false },
          { text: 'Valor', value: 'proposal', sortable: false },
          { text: 'Status', value: 'state', sortable: true }
        ],
        pagination: {
          search: sessionStorage.getItem('PaidTicketsPaginationSearch') || '',
          sortBy: [sessionStorage.getItem('PaidTicketsPaginationSortBy') || 'num_doc'],
          sortDesc: [JSON.parse(sessionStorage.getItem('PaidTicketsPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('PaidTicketsPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('PaidTicketsPaginationRowsPerPage') || 13),
          rowsPerPageItems: [13, 25, 50, 100]
        }
      }
    },
    computed: {
      ...mapGetters({
        bankSlips: 'bankSlips/list',
        loading: 'bankSlips/listLoading',
        isRender: 'bankSlips/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          // sessionStorage.setItem('PaidTicketsPaginationState', pagination.state)
          // sessionStorage.setItem('PaidTicketsPaginationSearch', pagination.search)
          // sessionStorage.setItem('PaidTicketsPaginationSortBy', pagination.sortBy)
          // sessionStorage.setItem('PaidTicketsPaginationDescending', pagination.descending)
          // sessionStorage.setItem('PaidTicketsPaginationRowsPerPage', pagination.rowsPerPage)
        }
      },
      deep: true
    },
    beforeMount () {
      this.$store.dispatch('bankSlips/getListByState', 'payed')
    },
    methods: {
      paginate (paginate) {
        // this.pagination = paginate
      }
    }
  }
</script>

<style lang="sass">
  .height-auto
    height: auto!important
    overflow: initial!important
    width: 100%

  .v-align-center.icon
    vertical-align: bottom

  .select-state
    position: relative
    top: 12px
</style>
