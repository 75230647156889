<template>
  <div class="list-cases">
    <app-list
      :total-entries="proposals.length"
      :entries="proposals"
      :headers="headers"
      :pagination="pagination"
      :is-render="isRender"
      :loading-list="loading"
      @paginate="paginate">
      <template v-slot:row="row">
        <row :props="row.props" />
      </template>
    </app-list>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row },
    data () {
      return {
        headers: [
          { text: 'Código', value: 'code', sortable: false },
          { text: 'Nome', value: 'recipient_name', sortable: false },
          { text: 'Efetivado em', value: 'insertedAt', sortable: false },
          { text: 'Parcelas', value: 'installmentSelected.parcel', sortable: false },
          { text: 'Parcelas Pagas', value: 'paidParcels.parcels', sortable: false },
          { text: 'Valor Pago', value: 'paidParcels.total', sortable: false },
          { text: 'Parcelas a Receber', value: 'toReceiveParcels.parcels', sortable: false },
          { text: 'Valor a Receber', value: 'toReceiveParcels.total', sortable: false },
          { text: 'Valor Total', value: 'installmentSelected.currentValue', sortable: false }
        ],
        pagination: {
          sortBy: sessionStorage.getItem('ProposalPaymentsPaginationSortBy') || 'updated_at',
          descending: JSON.parse(sessionStorage.getItem('ProposalPaymentsPaginationDescending') || true),
          page: parseInt(sessionStorage.getItem('ProposalPaymentsPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('ProposalPaymentsPaginationRowsPerPage') || 13),
          rowsPerPageItems: [13, 25, 50, 100]
        },
        proposalId: null
      }
    },
    computed: {
      ...mapGetters({
        proposals: 'proposal/list',
        loading: 'proposal/listLoading',
        isRender: 'proposal/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          // sessionStorage.setItem('ProposalPaymentsPaginationSortBy', pagination.sortBy)
          // sessionStorage.setItem('ProposalPaymentsPaginationDescending', pagination.descending)
          // sessionStorage.setItem('ProposalPaymentsPaginationRowsPerPage', pagination.rowsPerPage)
        },
        deep: true
      }
    },
    beforeMount () {
      this.$store.dispatch('proposal/getList', 'accepted')
    },
    methods: {
      paginate (paginate) {
        this.pagination = paginate
      }
    }
  }
</script>
