<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary" />
    <router-link
      v-else
      :to="{ name: routerName, params: routerParams }">
      {{ text }}
    </router-link>
  </div>
</template>

<script>
  import { processData } from '@/utils'

  export default {
    props: {
      promise: Promise,
      promiseKey: String,
      promiseId: {
        type: String,
        default: 'id'
      },
      routerName: {
        type: String,
        required: true
      },
      routerPath: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loading: true
    }),
    asyncComputed: {
      async data () {
        this.loading = true
        const response = await this.promise
        if (response) {
          const data = await processData(response)
          return data
        }
      },
      async text () {
        return this.data && this.data[this.promiseKey] || ''
      },
      async routerParams () {
        let routerParams = {}
        routerParams[this.routerPath] = this.data && this.data[this.promiseId]
        this.loading = false

        return routerParams
      }
    }
  }
</script>
