<template>
  <div class="list-cases">
    <app-list
      data-table-class="hideFooterSelect"
      :total-entries="length"
      :entries="proposals"
      :headers="headers"
      :pagination="pagination"
      :is-render="isRender"
      :loading-list="loading"
      @paginate="paginate">
      <template v-slot:row="row">
        <row :props="row.props" />
      </template>
    </app-list>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  const rowHeight = 48
  const compensationArea = 300
  const pageSize = () => parseInt((window.innerHeight - compensationArea) / rowHeight)

  export default {
    components: { AppList, Row },
    data () {
      return {
        headers: [
          { text: 'Código', value: 'code', sortable: false },
          { text: 'Nome', value: 'recipient_name', sortable: false },
          { text: 'Valor Original', value: 'installmentSelected.originalValue', sortable: false },
          { text: 'Acréscimos', value: 'additions', align: 'center', sortable: false },
          { text: 'Valor Total', value: 'installmentSelected.currentValue', sortable: false },
          { text: 'Efetivado em', value: 'updated_at', sortable: false },
          { text: 'Termo de Acordo', value: 'termFile', sortable: false }
        ],
        pagination: {
          state: 'accepted',
          sortBy: [sessionStorage.getItem('ProposalPaginationSortBy') || 'updateAt'],
          sortDesc: [JSON.parse(sessionStorage.getItem('ProposalPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('ProposalPaginationPage') || 1),
          rowsPerPage: pageSize(),
          rowsPerPageItems: [pageSize()]
        }
      }
    },
    computed: {
      ...mapGetters({
        length: 'proposal/length',
        proposals: 'proposal/list',
        loading: 'proposal/listLoading',
        isRender: 'proposal/listRender'
      })
    },
    methods: {
      paginate (paginate) {
        this.$store.dispatch('proposal/getList', paginate)
        this.pagination = paginate
      }
    }
  }
</script>
