<template>
  <tr
    tabindex="0"
    class="not-read">
    <td>
      <promise-link
        :promise="props.item.case"
        router-name="CaseChannelsList"
        router-path="caseId"
        promise-key="code" />
    </td>
    <td class="text-center">
      {{ props.item.reason.origin }}
    </td>
    <td class="text-center">
      {{ props.item.reason.status }}
    </td>
    <td class="text-center">
      {{ props.item.reason.event }}
    </td>
    <td class="text-center">
      {{ props.item.insertedAt }}
    </td>
    <td class="text-center">
      <term-download :file="props.item.file" />
    </td>
  </tr>
</template>

<script>
  import PromiseLink from '@/components/ui/PromiseLink'
  import TermDownload from '@/components/ui/TermDownload'

  export default {
    components: { PromiseLink, TermDownload },
    props: {
      props: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
