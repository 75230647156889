export const CHANNELS_STATE = {
  email: {
    dropped: 'dropped',
    bounce: 'bounce',
    clicked: 'clicked'
  },
  sms: {},
  letter: {}
}

export const CASE_STATE = {
  refused: 'refused',
  accepted: 'accepted',
  expired: 'expired',
  initialProposal: 'initialProposal',
  negotiation: 'negotiation',
  mediation: 'mediation'
}

export const PROPOSAL_STATE = {
  created: 'created',
  visualized: 'visualized',
  refused: 'refused',
  accepted: 'accepted',
  expired: 'expired'
}
