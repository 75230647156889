<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary" />

    <v-btn
      v-else
      :href="fileUrl"
      target="blank"
      depressed
      small
      color="accent">
      Visualizar Termo
    </v-btn>
  </div>
</template>

<script>
  import { processData } from '@/utils'

  export default {
    props: {
      file: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      loading: true,
      fileUrl: ''
    }),
    beforeMount () {
      const storage =this.firebase.storage()
      const pathReference = storage.ref(this.file)

      pathReference.getDownloadURL().then(fileUrl => {
        this.fileUrl = fileUrl
        this.loading = false
      })
    }
  }
</script>
