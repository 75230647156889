<template>
  <tr>
    <td>{{ props.item.code }}</td>
    <td>
      <promise-key
        :promise="props.item.case"
        promise-key="recipient.name" />
    </td>
    <td>{{ $n(props.item.installmentSelected.originalValue, 'currency') }}</td>
    <td>{{ $n(props.item.installmentSelected.currentValue, 'currency') }}</td>
    <td>{{ props.item.updatedAt }}</td>
    <td>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on">
            <v-icon color="grey lighten-1">
              info
            </v-icon>
          </v-btn>
        </template>
        <span>
          <strong>Valor</strong> {{ props.item.refused.amount }}
          <br>
          <strong>Parcel</strong> {{ props.item.refused.parcel }}
          <br>
          <strong>Mensagem</strong> {{ props.item.refused.note }}
          <br>
          <strong>Razão</strong> {{ props.item.refused.reason }}
        </span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
  import PromiseKey from '@/components/ui/PromiseKey'
  export default {
    components: { PromiseKey },
    props: {
      props: Object
    }
  }
</script>
