<template>
  <div class="container-page">
    <app-list
      :total-entries="length"
      :entries="terms"
      :headers="headers"
      :pagination="pagination"
      :is-render="isRender"
      :loading-list="loading"
      :has-search="false"
      :has-filter="false"
      :has-chart="false"
      @paginate="paginate">
      <template v-slot:row="row">
        <row :props="row.props" />
      </template>
    </app-list>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      AppList,
      Row
    },
    data () {
      return {
        messageModal: '',
        showModal: false,
        headers: [
          { text: 'Caso', value: 'code', sortable: false },
          { text: 'Criado em', value: 'insertedAt', align: 'center', sortable: false },
          { text: 'Certidão de Recusa', value: 'file', align: 'center', sortable: false }
        ],
        pagination: {
          page: parseInt(sessionStorage.getItem('RefusalCertificatesPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('RefusalCertificatesPaginationRowsPerPage') || 12),
          rowsPerPageItems: [12, 25, 50, 100]
        },
        series: [],
        rowSelected: {}
      }
    },
    computed: {
      ...mapGetters({
        length: 'refusalCertificates/length',
        terms: 'refusalCertificates/list',
        loading: 'refusalCertificates/listLoading',
        isRender: 'refusalCertificates/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          sessionStorage.setItem('RefusalCertificatesPaginationRowsPerPage', pagination.rowsPerPage)
          this.$store.dispatch('refusalCertificates/getList', pagination)
        },
        deep: true
      },
      deep: true
    },
    beforeMount () {
      this.$store.dispatch('refusalCertificates/getList', this.pagination)
    },
    methods: {
      paginate (paginate) {
        this.pagination = paginate
      }
    }
  }
</script>
